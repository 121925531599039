import React from 'react';
import { ToastOptions } from 'react-toastify';

import { Coordinates } from '../data/coordinates';

import './Geolocate.scss';

interface GeolocateProps {
    onGeolocate: (coords: Coordinates) => void;
    onFail: (message: string, options: ToastOptions) => void;
}

interface GeolocateState {
}

export default class Geolocate extends React.Component<GeolocateProps, GeolocateState> {
    public constructor(props: GeolocateProps) {
        super(props);
    }

    private async geolocate(): Promise<void> {
        const { onGeolocate, onFail } = this.props;

        return new Promise<void>((resolve, reject) => {
            navigator.geolocation.getCurrentPosition((position) => {
                onGeolocate(position.coords as Coordinates);
                resolve();
            }, (error) => {
                onFail('Une erreur est survenue. Veuillez vérifiez que vous acceptez la géolocalisation dans votre navigateur.', {});
                reject(error);
            }, {
                enableHighAccuracy: true,
                timeout: 6000,
            });
        });
    }

    public render(): React.ReactElement | null {
        return (
            <div id="geolocate">
                <button onClick={(): Promise<void> => this.geolocate()} className="button primary" type="button">
                    <span className="material-icons">gps_fixed</span>
                </button>
            </div>
        );
    }
}

import { Point } from 'geojson';
import React, { ChangeEvent, FormEvent } from 'react';
import { ToastOptions } from 'react-toastify';
import { debounce } from 'ts-debounce';

import Modal from './Modal';
import { Coordinates } from '../data/coordinates';
import { EstimateRequest } from '../data/estimate';
import { Place } from '../data/place';
import { PlaceType } from '../data/placeType';
import { PropertyType } from '../data/propertyType';
import { PlaceService } from '../services/placeService';
import './EstimateModal.scss';

interface EstimateModalProps {
    onClose: () => void;
    onSubmit: (request: EstimateRequest) => Promise<void>;
    onFail: (message: string, options: ToastOptions) => void;
}

interface EstimateModalState {
    addressSuggestions: Place<Point>[];
    currentlyGeolocating: boolean;
    currentlyEstimating: boolean;
    currentlySearching: boolean;

    addressName: string;
    type: PropertyType;
    coordinates: Coordinates | null;
    buildingSurfaceArea: number | null;
    landSurfaceArea: number | null;
    rooms: number | null;
}

export default class EstimateModal extends React.Component<EstimateModalProps, EstimateModalState> {
    private placeService: PlaceService;

    public constructor(props: EstimateModalProps) {
        super(props);
        this.placeService = new PlaceService();
        this.state = {
            addressSuggestions: [],
            currentlyGeolocating: false,
            currentlyEstimating: false,
            currentlySearching: false,
            addressName: '',

            type: PropertyType.HOUSE,
            coordinates: null,
            buildingSurfaceArea: null,
            landSurfaceArea: null,
            rooms: null,
        };
    }

    private updateAddressSuggestions = debounce(async (): Promise<void> => {
        const { addressName } = this.state;

        if (addressName === '') {
            return;
        }

        let suggestions;
        this.setState({ currentlySearching: true });

        try {
            suggestions = await this.placeService.getPlacesByName(addressName, PlaceType.ADDRESS);
        } catch {
            const { onFail } = this.props;
            onFail('Une erreur est survenue lors de la recherche d\'adresse', {});
            return;
        } finally {
            this.setState({ currentlySearching: false });
        }

        this.setState({ addressSuggestions: suggestions as Place<Point>[] });
    }, 500);

    private async submit(event: FormEvent<HTMLFormElement>): Promise<void> {
        event.preventDefault();

        const {
            type, buildingSurfaceArea, landSurfaceArea, rooms,
        } = this.state;

        const { onSubmit, onFail } = this.props;

        let { coordinates } = this.state;

        if (!buildingSurfaceArea || !rooms) {
            return;
        }

        if (!coordinates) {
            const { addressName } = this.state;
            let addressSearch;

            try {
                addressSearch = await this.placeService.getPlacesByName(addressName, PlaceType.ADDRESS, 1);
            } catch {
                addressSearch = null;
            }

            if (!addressSearch?.[0]) {
                onFail('Une erreur est survenue lors de la recherche d\'adresse', {});
                return;
            }

            const place = addressSearch[0];

            if (!place.geometry) {
                return;
            }

            const point = place.geometry as Point;

            coordinates = {
                longitude: point.coordinates[0],
                latitude: point.coordinates[1],
            };
        }

        const request: EstimateRequest = {
            type, coordinates, buildingSurfaceArea, landSurfaceArea: (landSurfaceArea ?? 0), rooms,
        };

        this.setState({ currentlyEstimating: true });

        try {
            await onSubmit(request);
        } catch {
            onFail('Une erreur est survenue lors de l\'estimation', {});
        } finally {
            this.setState({ currentlyEstimating: false });
        }
    }

    private getCoordinates(): Promise<Coordinates> {
        return new Promise<Coordinates>((resolve, reject): void => {
            navigator.geolocation.getCurrentPosition((position) => {
                const coordinates: Coordinates = {
                    longitude: position.coords.longitude,
                    latitude: position.coords.latitude,
                };

                resolve(coordinates);
            }, (error) => {
                reject(error);
            }, {
                enableHighAccuracy: true,
                timeout: 6000,
            });
        });
    }

    private async geolocate(): Promise<void> {
        this.setState({ currentlyGeolocating: true });

        const fail = (message: string): void => {
            this.setState({ currentlyGeolocating: false });
            const { onFail } = this.props;
            onFail(message, {});
        };

        let coordinates;

        try {
            coordinates = await this.getCoordinates();
        } catch {
            fail('Une erreur est survenue lors de la géolocalisation');
            return;
        }

        let suggestions;

        try {
            suggestions = await this.placeService.getPlacesAtCoordinates(coordinates, PlaceType.ADDRESS);
        } catch {
            fail('Une erreur est survenue lors de la recherche d\'addresse');
            return;
        }

        if (suggestions.length === 0) {
            fail('Aucune addresse n\'a été trouvée à proximité de vous');
            return;
        }

        const place = await this.placeService.getPlaceHierarchy(suggestions[0].id, suggestions[0].type) as Place<Point>;

        if (!place.geometry) {
            return;
        }

        this.setState({
            currentlyGeolocating: false,
            addressName: place.name,
            coordinates: {
                longitude: place.geometry.coordinates[0],
                latitude: place.geometry.coordinates[1],
            },
        });
    }

    private async onAddressChanged(event: ChangeEvent<HTMLInputElement>): Promise<void> {
        this.setState({
            addressName: event.target.value,
        });
        await this.updateAddressSuggestions();
    }

    private async onAddressSuggestionClick(suggestion: Place<Point>): Promise<void> {
        const place = await this.placeService.getPlaceHierarchy(suggestion.id, suggestion.type) as Place<Point>;

        if (!place.geometry) {
            return;
        }

        this.setState({
            coordinates: {
                longitude: place.geometry.coordinates[0],
                latitude: place.geometry.coordinates[1],
            },
            addressName: suggestion.name,
        });
    }

    public render(): React.ReactElement {
        const { onClose } = this.props;
        const {
            type,
            addressName,
            currentlyGeolocating,
            currentlyEstimating,
            currentlySearching,
            addressSuggestions,
            buildingSurfaceArea,
            landSurfaceArea,
            rooms,
        } = this.state;

        const suggestionItems = addressSuggestions.map(
            (address): React.ReactElement => (
                <div
                    className="item"
                    role="button"
                    tabIndex={0}
                    key={address.id}
                    onClick={async (): Promise<void> => { await this.onAddressSuggestionClick(address); }}
                    onKeyDown={async (event): Promise<void> => { if (event.key === 'Enter') { await this.onAddressSuggestionClick(address); } }}
                >
                    {address.name}
                </div>
            ),
        );

        return (
            <Modal title="Estimer mon bien" onClose={onClose}>
                <p className="mt0 mb2">
                    Grâce à notre base de données de transactions foncières, nous vous proposons un outil qui vous
                    permet d&apos;estimer la valeur de votre bien selon certains critères.
                </p>

                <form autoComplete="off" onSubmit={async (event): Promise<void> => { await this.submit(event); }}>
                    <label className="mb">Type de bien</label>
                    <div className="row gaps mb2">
                        <div className="sm-col6">
                            <div className="estimate-type-radio">
                                <input
                                    type="radio"
                                    name="estimate-type"
                                    id="estimate-house"
                                    value="house"
                                    onChange={(): void => { this.setState({ type: PropertyType.HOUSE }); }}
                                    checked={type === PropertyType.HOUSE}
                                />
                                <label htmlFor="estimate-house">
                                    <span className="material-icons">house</span>
                                    Maison
                                </label>
                            </div>
                        </div>
                        <div className="sm-col6">
                            <div className="estimate-type-radio">
                                <input
                                    type="radio"
                                    name="estimate-type"
                                    id="estimate-apartment"
                                    value="apartment"
                                    onChange={(): void => { this.setState({ type: PropertyType.APARTMENT }); }}
                                    checked={type === PropertyType.APARTMENT}
                                />
                                <label htmlFor="estimate-apartment">
                                    <span className="material-icons"> apartment</span>
                                    Appartement
                                </label>
                            </div>
                        </div>
                    </div>

                    <label htmlFor="estimate-address">Adresse</label>
                    <div className="row mb2">
                        <div className="sm-col12 sm-mb md-col9">
                            <div className="address-autocomplete">
                                <div className={currentlySearching || currentlyGeolocating ? 'loader visible' : 'loader'} />
                                <input
                                    id="estimate-address"
                                    type="text"
                                    placeholder="&#xe8b6;&nbsp;&nbsp;Tapez pour rechercher"
                                    value={addressName}
                                    className={addressSuggestions.length > 0 ? 'has-suggestions' : ''}
                                    disabled={currentlyGeolocating}
                                    onChange={async (event): Promise<void> => this.onAddressChanged(event)}
                                    required
                                />
                                <div className="suggestions">
                                    {suggestionItems}
                                </div>
                            </div>
                        </div>
                        <div className="sm-col12 md-col3 md-pl">
                            <button
                                type="button"
                                className="button w100"
                                disabled={currentlyGeolocating}
                                onClick={async (): Promise<void> => { await this.geolocate(); }}
                            >
                                <span className="material-icons">my_location</span>
                                Me localiser
                            </button>
                        </div>
                    </div>

                    <div className="row gaps mb2">
                        <div className="sm-col12 sm-mb2 md-col4">
                            <label htmlFor="estimate-building-surface">
                                Surface bâtiment (m
                                <sup>2</sup>
                                )
                            </label>
                            <input
                                id="estimate-building-surface"
                                type="number"
                                placeholder="ex: 110"
                                value={buildingSurfaceArea || ''}
                                onChange={(event): void => { this.setState({ buildingSurfaceArea: +event.target.value }); }}
                                min={1}
                                required
                            />
                        </div>
                        <div className="sm-col12 sm-mb2 md-col4">
                            <label htmlFor="estimate-land-surface">
                                Surface terrain (m
                                <sup>2</sup>
                                )
                            </label>
                            <input
                                id="estimate-land-surface"
                                type="number"
                                placeholder="ex: 1000"
                                value={landSurfaceArea || ''}
                                onChange={(event): void => { this.setState({ landSurfaceArea: +event.target.value }); }}
                                min={0}
                            />
                        </div>
                        <div className="sm-col12 md-col4">
                            <label htmlFor="estimate-room-count">Nombre de pièces</label>
                            <input
                                id="estimate-room-count"
                                type="number"
                                placeholder="ex: 5"
                                value={rooms || ''}
                                onChange={(event): void => { this.setState({ rooms: +event.target.value }); }}
                                min={1}
                                required
                            />
                        </div>
                    </div>

                    <button
                        className="button primary right"
                        type="submit"
                        disabled={currentlyEstimating}
                    >
                        Estimer mon bien
                    </button>
                </form>
            </Modal>
        );
    }
}

import { Geometry } from 'geojson';
import React from 'react';
import {
    ToastContainer,
    toast,
    ToastOptions,
    Slide,
} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import AboutModal from './AboutModal';
import EstimateModal from './EstimateModal';
import Filter from './Filter';
import Geolocate from './Geolocate';
import Header from './Header';
import HelpModal from './HelpModal';
import InfoBox from './InfoBox';
import Map from './Map';
import './App.scss';
import { Coordinates } from '../data/coordinates';
import { EstimateRequest } from '../data/estimate';
import { SelectedFeature } from '../data/featureProperties';
import { MapFilter } from '../data/mapFilter';
import { Place } from '../data/place';
import { PropertyType } from '../data/propertyType';

interface AppProps {

}

interface AppState {
    isLoading: boolean;

    isEstimateModalOpen: boolean;
    isAboutModalOpen: boolean;
    isHelpModalOpen: boolean;

    selectedFeatures: SelectedFeature;
    mapFilter: MapFilter;
}

export default class App extends React.Component<AppProps, AppState> {
    private mapRef: React.RefObject<Map>;

    public constructor(props: AppProps) {
        super(props);

        const showHelp = window.localStorage.getItem('helpDismissed') === null;

        this.state = {
            isLoading: false,

            isEstimateModalOpen: false,
            isAboutModalOpen: false,
            isHelpModalOpen: showHelp,

            selectedFeatures: {
                country: null,
                region: null,
                department: null,
                town: null,
                section: null,
            },

            mapFilter: {
                type: PropertyType.ALL,
                year: null,
            },
        };
        this.mapRef = React.createRef();
    }

    public render(): React.ReactElement {
        const {
            isLoading, isEstimateModalOpen, isAboutModalOpen, isHelpModalOpen, selectedFeatures, mapFilter,
        } = this.state;

        return (
            <main>
                <Header
                    isLoading={isLoading}
                    onEstimateClick={(): void => { this.setEstimateModalOpen(true); }}
                    onHomeClick={async (): Promise<void> => { await this.parentZoomToParentCollection(0); }}
                    onAboutClick={(): void => { this.setAboutModalOpen(true); }}
                    onSearchClick={async (event): Promise<void> => this.searchClick(event)}
                />
                <Map
                    ref={this.mapRef}
                    handleSelectedFeatureChange={(sf: SelectedFeature): void => { this.parentHandleSelectedFeatureChange(sf); }}
                    setLoading={(l): void => { this.setState({ isLoading: l }); }}
                    filter={mapFilter}
                    toast={(
                        message: string,
                        type: 'info' | 'success' | 'warning' | 'error' | 'default',
                        options: ToastOptions = {},
                    ): void => { this.displayToast(message, type, options); }}
                />
                <Filter
                    filter={mapFilter}
                    onChange={(f): void => { this.setState({ mapFilter: f }); }}
                />
                <InfoBox
                    zoomToParentCollection={async (level: number): Promise<void> => { await this.parentZoomToParentCollection(level); }}
                    selectedFeatures={selectedFeatures}
                />
                { isEstimateModalOpen && (
                    <EstimateModal
                        onClose={(): void => { this.setEstimateModalOpen(false); }}
                        onSubmit={async (request): Promise<void> => { await this.onEstimateSubmit(request); }}
                        onFail={(message: string, options: ToastOptions = {}): void => { this.displayToast(message, 'error', options); }}
                    />
                )}
                { isAboutModalOpen && <AboutModal onClose={(): void => { this.setAboutModalOpen(false); }} />}
                { isHelpModalOpen && (
                    <HelpModal
                        onClose={(): void => { this.setState({ isHelpModalOpen: false }); }}
                        onPermanentlyDismiss={(): void => { this.permanentlyDismissHelp(); }}
                    />
                )}
                <Geolocate
                    onGeolocate={(coords: Coordinates): Promise<void> => this.parentOnGeolocate(coords)}
                    onFail={(message: string, options: ToastOptions = {}): void => { this.displayToast(message, 'error', options); }}
                />
                <ToastContainer
                    position="bottom-center"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    transition={Slide}
                />
            </main>
        );
    }

    private displayToast(message: string, type: 'info' | 'success' | 'warning' | 'error' | 'default' = 'default', options: ToastOptions = {}): void {
        switch (type) {
        case 'info':
            toast.info(message, options);
            break;
        case 'success':
            toast.success(message, options);
            break;
        case 'warning':
            toast.warning(message, options);
            break;
        case 'error':
            toast.error(message, options);
            break;
        default:
            toast(message, options);
            break;
        }
    }

    private setEstimateModalOpen(isOpen: boolean): void {
        this.setState({
            isEstimateModalOpen: isOpen,
        });
    }

    private setAboutModalOpen(isOpen: boolean): void {
        this.setState({
            isAboutModalOpen: isOpen,
        });
    }

    private permanentlyDismissHelp(): void {
        this.setState({
            isHelpModalOpen: false,
        });

        window.localStorage.setItem('helpDismissed', 'true');
    }

    private parentHandleSelectedFeatureChange(selectedFeatures: SelectedFeature): void {
        this.setState({
            selectedFeatures,
        });
    }

    private async parentOnGeolocate(coords: Coordinates): Promise<void> {
        await this.mapRef.current?.onGeolocate(coords);
    }

    private async parentZoomToParentCollection(level: number): Promise<void> {
        await this.mapRef.current?.zoomToParentCollection(level);
    }

    private async searchClick(place: Place<Geometry>): Promise<void> {
        await this.mapRef.current?.moveToPlace(place);
    }

    private async onEstimateSubmit(request: EstimateRequest): Promise<void> {
        this.setEstimateModalOpen(false);
        await this.mapRef.current?.getEstimate(request);
    }
}

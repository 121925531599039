import { ApiService } from './apiService';
import
{
    DepartmentProperties, RegionProperties, SaleProperties, SectionProperties, TownProperties,
} from '../data/featureProperties';
import { FeaturesData } from '../data/featuresData';
import { PropertyType } from '../data/propertyType';
import { RequestParams } from '../data/requestParams';

export class CadastreService extends ApiService {
    public getRegions(salesYear: number | null): Promise<FeaturesData<RegionProperties>> {
        const params: RequestParams = {};

        if (salesYear) {
            params.salesYear = salesYear;
        }

        return this.makeGetRequest('/regions', params);
    }

    public getDepartments(regionId: string, salesYear: number | null): Promise<FeaturesData<DepartmentProperties>> {
        const params: RequestParams = {};

        if (salesYear) {
            params.salesYear = salesYear;
        }

        return this.makeGetRequest(`/regions/${regionId}/departments`, params);
    }

    public getTowns(departmentId: string, salesYear: number | null): Promise<FeaturesData<TownProperties>> {
        const params: RequestParams = {};

        if (salesYear) {
            params.salesYear = salesYear;
        }

        return this.makeGetRequest(`/departments/${departmentId}/towns`, params);
    }

    public getSections(townId: string, salesYear: number | null): Promise<FeaturesData<SectionProperties>> {
        const params: RequestParams = {};

        if (salesYear) {
            params.salesYear = salesYear;
        }

        return this.makeGetRequest(`/towns/${townId}/sections`, params);
    }

    public getSales(sectionId: string, type: PropertyType, year: number | null): Promise<FeaturesData<SaleProperties>> {
        const params: RequestParams = {};

        if (type !== PropertyType.ALL) {
            params.type = type;
        }

        if (year) {
            params.year = year;
        }

        return this.makeGetRequest(`/sections/${sectionId}/property-sales`, params);
    }
}

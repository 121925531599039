import { Geometry } from 'geojson';
import React from 'react';

import Autocomplete from './Autocomplete';
import { AutocompleteSuggestion } from '../data/autocompleteSuggestion';
import { Place } from '../data/place';
import { PlaceType } from '../data/placeType';
import { PlaceService } from '../services/placeService';

import './Header.scss';

interface HeaderProps {
    isLoading: boolean;
    onHomeClick: () => void;
    onEstimateClick: () => void;
    onAboutClick: () => void;
    onSearchClick: (place: Place<Geometry>) => void;
}

interface HeaderState {
    isNavOpen: boolean;
    searchSuggestions: AutocompleteSuggestion[];
    places: Place<Geometry>[];
}

export default class Header extends React.Component<HeaderProps, HeaderState> {
    private placeService: PlaceService;

    public constructor(props: HeaderProps) {
        super(props);
        this.placeService = new PlaceService();
        this.state = {
            isNavOpen: false,
            searchSuggestions: [],
            places: [],
        };
    }

    private toggleNav(): void {
        this.setState((prev) => ({
            isNavOpen: !prev.isNavOpen,
        }));
    }

    public render(): React.ReactElement {
        const {
            searchSuggestions,
            isNavOpen,
        } = this.state;

        const {
            isLoading,
            onEstimateClick,
            onAboutClick,
        } = this.props;

        return (
            <header id="head">
                <div className="wrapper sm-pr md-pl3 md-pr3">
                    <button type="button" onClick={this.toggleNav.bind(this)} id="open-nav">
                        <span className="material-icons">menu</span>
                    </button>

                    <button type="button" onClick={(): void => { this.onHomeClick(); }} id="logo" className="sm-mr2 md-mr3">
                        <img src="img/logo.svg" alt="Estimmo" />
                    </button>

                    <div id="search" className="md-mr2">
                        <Autocomplete
                            placeholder="&#xe8b6;&nbsp;&nbsp;Rechercher par adresse, commune, département ou région..."
                            suggestions={searchSuggestions}
                            onSearchUpdate={async (event): Promise<void> => this.onAutocompleteSearch(event)}
                            onSuggestionClick={(suggestion): void => { this.onSearchClick(suggestion as string); }}
                        />
                    </div>

                    <nav id="nav" className={isNavOpen ? 'visible' : ''}>
                        <button type="button" onClick={this.toggleNav.bind(this)} id="close-nav">Fermer</button>

                        <div className="links">
                            <button
                                type="button"
                                className="link"
                                onClick={(): void => {
                                    this.setState({ isNavOpen: false });
                                    onAboutClick();
                                }}
                            >
                                À Propos
                            </button>
                        </div>

                        <button
                            type="button"
                            className="button primary estimate sm-ml sm-mt md-mr2"
                            onClick={(): void => {
                                this.setState({ isNavOpen: false });
                                onEstimateClick();
                            }}
                        >
                            Estimer mon bien
                        </button>
                    </nav>
                </div>

                { isLoading && (
                    <div id="loader">
                        <div className="value" />
                    </div>
                )}
            </header>
        );
    }

    private onHomeClick(): void {
        const { onHomeClick } = this.props;
        onHomeClick();
    }

    private async onAutocompleteSearch(search: string): Promise<void> {
        const places = await this.placeService.getPlacesByName(search);

        this.setState({
            places,
            searchSuggestions: places.map((place) => {
                let subLabel;

                switch (place.type) {
                case PlaceType.REGION: subLabel = 'Région'; break;
                case PlaceType.DEPARTMENT: subLabel = 'Département'; break;
                case PlaceType.TOWN: subLabel = 'Commune'; break;
                case PlaceType.STREET: subLabel = 'Voie'; break;
                case PlaceType.SAID_PLACE: subLabel = 'Lieu dit'; break;
                case PlaceType.ADDRESS: subLabel = 'Adresse'; break;
                default: break;
                }

                return {
                    label: place.name,
                    value: place.id,
                    subLabel,
                };
            }),
        });
    }

    private onSearchClick(suggestion: string): void {
        const { places } = this.state;

        const place = places.find((p) => p.id === suggestion);

        if (place) {
            const { onSearchClick } = this.props;
            onSearchClick(place);
        }
    }
}

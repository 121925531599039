import React, { ChangeEvent } from 'react';
import { debounce } from 'ts-debounce';

import { AutocompleteSuggestion } from '../data/autocompleteSuggestion';
import './Autocomplete.scss';

interface AutocompleteProps {
    placeholder: string;
    suggestions: AutocompleteSuggestion[];
    disabled?: boolean;
    onSearchUpdate: (search: string) => Promise<void>;
    onSuggestionClick: (suggestionValue: string | number) => void;
}

interface AutocompleteState {
    search: string;
    cursor: number;
    showSuggestions: boolean;
    isLoading: boolean;
}

export default class Autocomplete extends React.Component<AutocompleteProps, AutocompleteState> {
    public static defaultProps = {
        disabled: false,
    };

    private wrapperRef: React.RefObject<HTMLDivElement>;

    public constructor(props: AutocompleteProps) {
        super(props);
        this.state = {
            search: '',
            cursor: 0,
            showSuggestions: false,
            isLoading: false,
        };
        this.wrapperRef = React.createRef();
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    public componentDidMount(): void {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    public componentWillUnmount(): void {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    public handleClickOutside(event: MouseEvent): void {
        if (this.wrapperRef.current && !this.wrapperRef.current.contains(event.target as Node)) {
            this.setState({
                showSuggestions: false,
            });
        }
    }

    public render(): React.ReactElement {
        const {
            placeholder,
            suggestions,
            disabled,
        } = this.props;

        const {
            cursor,
            search,
            showSuggestions,
            isLoading,
        } = this.state;

        const suggestionItems = suggestions.map(
            (suggestion, index): React.ReactElement => (
                // eslint-disable-next-line jsx-a11y/click-events-have-key-events
                <div
                    className={index === cursor ? 'item focused' : 'item'}
                    role="button"
                    tabIndex={0}
                    key={suggestion.value}
                    onClick={(): void => { this.onSuggestionSelect(suggestion); }}
                    onMouseEnter={(): void => { this.onSuggestionMouseEnter(index); }}
                >
                    {suggestion.label}
                    {suggestion.subLabel && <small className="sub-label">{suggestion.subLabel}</small>}
                </div>
            ),
        );

        return (
            <div ref={this.wrapperRef} className="autocomplete">
                <input
                    id="estimate-address"
                    type="text"
                    autoComplete="off"
                    placeholder={placeholder}
                    value={search}
                    className={suggestions.length > 0 ? 'has-suggestions' : ''}
                    disabled={disabled}
                    onClick={(): void => { this.setState({ showSuggestions: true }); }}
                    onChange={async (event): Promise<void> => this.onSearchChanged(event)}
                    onKeyDown={(event: unknown): void => { this.onSuggestionNavigate(event as KeyboardEvent); }}
                />
                <div className={isLoading ? 'loader visible' : 'loader'} />
                {showSuggestions
                && (
                    <div className="suggestions">
                        {suggestionItems}
                    </div>
                )}
            </div>
        );
    }

    private debounceSearch = debounce(async (): Promise<void> => {
        const { search } = this.state;

        if (search === '') {
            return;
        }

        const { onSearchUpdate } = this.props;
        this.setState({ isLoading: true });

        try {
            await onSearchUpdate(search);
        } finally {
            this.setState({ isLoading: false });
        }
    }, 500);

    private async onSearchChanged(event: ChangeEvent<HTMLInputElement>): Promise<void> {
        this.setState({
            search: event.target.value,
            showSuggestions: true,
        });

        await this.debounceSearch();
    }

    private onSuggestionSelect(suggestion: AutocompleteSuggestion): void {
        this.setState({
            search: suggestion.label,
            showSuggestions: false,
        });

        const { onSuggestionClick } = this.props;
        onSuggestionClick(suggestion.value);
    }

    private onSuggestionNavigate(event: KeyboardEvent): void {
        const { cursor } = this.state;
        const { suggestions } = this.props;

        if (event.code === 'ArrowUp' && cursor > 0) {
            this.setState((prevState) => ({
                cursor: prevState.cursor - 1,
            }));
        } else if (event.code === 'ArrowDown' && cursor < suggestions.length - 1) {
            this.setState((prevState) => ({
                cursor: prevState.cursor + 1,
            }));
        } else if (event.code === 'Enter') {
            this.onSuggestionSelect(suggestions[cursor]);
        }
    }

    private onSuggestionMouseEnter(index: number): void {
        this.setState({
            cursor: index,
        });
    }
}

import { EChartsOption } from 'echarts';
import ReactECharts from 'echarts-for-react';
import React from 'react';

import { SelectedFeature } from '../data/featureProperties';
import { PropertyType } from '../data/propertyType';
import './InfoBox.scss';

interface InfoBoxProps {
    selectedFeatures: SelectedFeature;
    zoomToParentCollection: (level: number) => Promise<void>;
}

interface InfoBoxState {
    showPriceChart: boolean;
}

export default class InfoBox extends React.Component<InfoBoxProps, InfoBoxState> {
    private numberFormat = new Intl.NumberFormat('fr-FR', {
        maximumFractionDigits: 0,
    });

    private currentMedianValuesByYear: Record<PropertyType, Record<number, number> | undefined> | null = null;

    public constructor(props: InfoBoxProps) {
        super(props);
        this.state = {
            showPriceChart: false,
        };
    }

    private async handleZoomToParentCollectionClick(level: number): Promise<void> {
        const { zoomToParentCollection } = this.props;
        await zoomToParentCollection(level);
    }

    private getOption(): EChartsOption {
        if (!this.currentMedianValuesByYear) {
            return {};
        }

        return {
            tooltip: {
                trigger: 'axis',
                valueFormatter: (value): string => `${this.numberFormat.format(value as number)} € m2`,
            },
            legend: {
                data: ['Maison', 'Appartement'],
            },
            xAxis: {
                data: Object.keys(this.currentMedianValuesByYear[PropertyType.HOUSE] || {}),
            },
            yAxis: {
                min: (value): number => Math.floor(value.min / 100) * 100,
                max: (value): number => Math.ceil(value.max / 100) * 100,
            },
            series: [
                ...(
                    this.currentMedianValuesByYear[PropertyType.HOUSE]
                        ? [{
                            name: 'Maison',
                            type: 'line' as const, // bypass echarts type problem
                            data: Object.values(this.currentMedianValuesByYear[PropertyType.HOUSE]),
                            color: '#32a0ff',
                        }]
                        : []
                ),
                ...(
                    this.currentMedianValuesByYear[PropertyType.APARTMENT]
                        ? [{
                            name: 'Appartement',
                            type: 'line' as const, // bypass echarts type problem
                            data: Object.values(this.currentMedianValuesByYear[PropertyType.APARTMENT]),
                            color: '#f1911d',
                        }]
                        : []
                ),

            ],
            grid: {
                left: 40,
                top: 40,
                right: 0,
                height: 250,
            },
            textStyle: {
                fontFamily: 'Rubik',
            },
        };
    }

    public render(): React.ReactElement | null {
        let showPriceChartButton = true;
        const { showPriceChart } = this.state;
        const { selectedFeatures } = this.props;
        const currentFeature = selectedFeatures.town
            || selectedFeatures.department
            || selectedFeatures.region
            || selectedFeatures.country;

        if (!currentFeature) {
            return null;
        }

        this.currentMedianValuesByYear = currentFeature.medianValuesByYear;

        let currentMedianValues = currentFeature.medianValues || null;
        let currentValueStats = currentFeature.valueStats || null;

        if (selectedFeatures.section) {
            currentMedianValues = selectedFeatures.section.medianValues || null;
            currentValueStats = selectedFeatures.town?.valueStats || null;
            showPriceChartButton = false;
        }

        return (
            <div id="info-box">
                <div className="heading">
                    <div className="title">
                        <div className="name">
                            {selectedFeatures.section ? selectedFeatures.town?.name : currentFeature.name}
                            {selectedFeatures.section?.id || selectedFeatures.town?.id || selectedFeatures.department?.id ? (
                                <small>
                                    (
                                    {selectedFeatures.section?.id || selectedFeatures.town?.postCode || selectedFeatures.department?.id}
                                    )
                                </small>
                            ) : ''}
                        </div>

                        <div className="breadcrumbs">
                            {selectedFeatures.region
                                ? (
                                    <button
                                        type="button"
                                        className="link"
                                        onClick={async (): Promise<void> => { await this.handleZoomToParentCollectionClick(0); }}
                                    >
                                        France
                                    </button>
                                ) : ''}
                            {selectedFeatures.department
                                ? (
                                    <button
                                        type="button"
                                        className="link"
                                        onClick={async (): Promise<void> => { await this.handleZoomToParentCollectionClick(1); }}
                                    >
                                        {selectedFeatures.region?.name}
                                    </button>
                                ) : ''}
                            {selectedFeatures.town
                                ? (
                                    <button
                                        type="button"
                                        className="link"
                                        onClick={async (): Promise<void> => { await this.handleZoomToParentCollectionClick(2); }}
                                    >
                                        {selectedFeatures.department?.name}
                                    </button>
                                ) : ''}
                        </div>
                    </div>

                    {showPriceChartButton
                    && (
                        <button
                            type="button"
                            className="button chart-button"
                            onClick={(): void => { this.setState({ showPriceChart: !showPriceChart }); }}
                        >
                            <span className="material-icons">
                                show_chart
                            </span>
                        </button>
                    )}

                </div>

                <div className="row mt mb md-mt2 md-mb2">
                    {currentMedianValues && currentMedianValues['1']
                        ? (
                            <div className="sm-col6 value">
                                <span className="material-icons mb">house</span>
                                <div>
                                    {(this.numberFormat.format(Math.round(currentMedianValues['1'])))}
                                    € m
                                    <sup>2</sup>
                                </div>
                            </div>
                        )
                        : ''}
                    {currentMedianValues && currentMedianValues['2']
                        ? (
                            <div className="sm-col6 value">
                                <span className="material-icons mb">apartment</span>
                                <div>
                                    {(this.numberFormat.format(Math.round(currentMedianValues['2'])))}
                                    € m
                                    <sup>2</sup>
                                </div>
                            </div>
                        )
                        : ''}
                </div>

                <div className="mb2">
                    <div className="scale mb" />
                    <span className="left">
                        {
                            (currentValueStats
                                ? this.numberFormat.format(Math.round(currentValueStats[0])) : 0)
                        }
                        € m
                        <sup>2</sup>
                    </span>
                    <span className="right">
                        {
                            (currentValueStats
                                ? this.numberFormat.format(Math.round(currentValueStats[1])) : 0)
                        }
                        € m
                        <sup>2</sup>
                    </span>
                </div>

                {showPriceChartButton && showPriceChart
                && (
                    <div id="history">
                        <div className="section-name">Évolution du prix</div>
                        <div id="history-chart">
                            <ReactECharts
                                option={this.getOption()}
                                style={{ height: '400px', width: '100%' }}
                            />
                        </div>
                    </div>
                )}

            </div>
        );
    }
}

import React from 'react';

import Modal from './Modal';

import './AboutModal.scss';

interface AboutModalProps {
    onClose: () => void;
}

interface AboutModalState {

}

export default class AboutModal extends React.Component<AboutModalProps, AboutModalState> {
    public constructor(props: AboutModalProps) {
        super(props);
    }

    public render(): React.ReactElement {
        const { onClose } = this.props;

        return (
            <Modal title="À Propos" onClose={onClose}>
                <img src="img/logo.svg" alt="Estimmo" />

                <p>
                    Estimmo est le site de référence pour les prix de l&apos;immobilier dans sa région, son département ou sa commune.
                    Grâce à notre base de données des transactions foncières, nous vous proposons une carte des prix et un historique
                    des biens immobiliers sur toute la France.
                </p>

                <p>
                    Nos données sont produites à partir de sources ouvertes. La documentation de l&apos;API du site est disponible en&nbsp;
                    <a href="https://estimmo-api.jmh.me/swagger" target="_blank" rel="noreferrer">OpenAPI 3.0 (Swagger)</a>
                    .
                </p>

                <h3>Nos données</h3>

                <div className="row">
                    <div className="sm-col6">
                        <ul className="mb0">
                            <li>
                                <strong>Régions et départements</strong>
                                <br />
                                <a href="https://github.com/gregoiredavid/france-geojson" rel="nofollow">Grégoire DAVID : France GeoJSON</a>
                            </li>
                            <li>
                                <strong>Communes</strong>
                                <br />
                                <a href="https://geo.api.gouv.fr/" rel="nofollow">Etalab Géo API</a>
                            </li>
                            <li>
                                <strong>Sections cadastrales</strong>
                                <br />
                                <a href="https://cadastre.data.gouv.fr/datasets/cadastre-etalab" rel="nofollow">Etalab Cadastre</a>
                            </li>
                        </ul>
                    </div>
                    <div className="sm-col6">
                        <ul className="mb0">
                            <li>
                                <strong>Adresses et lieux-dits</strong>
                                <br />
                                <a href="https://adresse.data.gouv.fr/" rel="nofollow">Base Adresse Nationale</a>
                            </li>
                            <li>
                                <strong>Transactions foncières</strong>
                                <br />
                                <a href="https://www.data.gouv.fr/fr/datasets/demandes-de-valeurs-foncieres-geolocalisees" rel="nofollow">
                                    Etalab DVF
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </Modal>
        );
    }
}

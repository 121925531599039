import {
    BBox, FeatureCollection, Geometry, Point,
} from 'geojson';
import mapbox, {
    AnySourceImpl, GeoJSONSource, LngLatBoundsLike, LngLatLike, MapboxGeoJSONFeature, Popup, Marker,
} from 'mapbox-gl';
import React from 'react';
import 'mapbox-gl/dist/mapbox-gl.css';
import './Map.scss';
import { ToastOptions } from 'react-toastify';

import { constants } from '../constants';
import { Coordinates } from '../data/coordinates';
import { EstimateRequest } from '../data/estimate';
import {
    MedianValueProperties,
    DepartmentProperties,
    MapBoxProperties,
    SectionProperties,
    SelectedFeature,
    TownProperties,
} from '../data/featureProperties';
import { FeaturesData } from '../data/featuresData';
import { MapFilter } from '../data/mapFilter';
import { Place } from '../data/place';
import { PlaceType } from '../data/placeType';
import { PropertyType } from '../data/propertyType';
import { CadastreService } from '../services/cadastreService';
import { EstimateService } from '../services/estimateService';
import { PlaceService } from '../services/placeService';
import { formatDate } from '../utils/date.utils';

mapbox.accessToken = constants.MapboxPublicToken;

interface MapProps {
    handleSelectedFeatureChange: (selectedFeatures: SelectedFeature) => void;
    setLoading: (isLoading: boolean) => void;
    toast: (message: string, type: 'info' | 'success' | 'warning' | 'error' | 'default', options: ToastOptions) => void;
    filter: MapFilter;
}

interface MapState extends SelectedFeature {
    regionsBounds: LngLatBoundsLike;
    departmentsBounds: LngLatBoundsLike | null;
    townsBounds: LngLatBoundsLike | null;

    propertySalePopup: Popup | null;
    locationMarker: Marker | null;

    hoveredFeatureIds: {
        [sourceId: string]: number | null;
    };
}

type MapMouseEvent = mapbox.MapMouseEvent & {
    features?: mapbox.MapboxGeoJSONFeature[] | undefined;
} & mapbox.EventData;

export default class Map extends React.Component<MapProps, MapState> {
    private numberFormat = new Intl.NumberFormat('fr-FR', {
        maximumFractionDigits: 0,
    });

    private map: mapbox.Map | null = null;
    private readonly mapElement: React.RefObject<HTMLDivElement>;
    private readonly cadastreService: CadastreService;
    private readonly placeService: PlaceService;
    private readonly estimateService: EstimateService;

    public constructor(props: MapProps) {
        super(props);
        this.state = {
            country: null,
            region: null,
            department: null,
            town: null,
            section: null,

            regionsBounds: [[-5.92, 40.88], [10.91, 52.0]],
            departmentsBounds: null,
            townsBounds: null,

            propertySalePopup: null,
            locationMarker: null,

            hoveredFeatureIds: {},
        };

        this.mapElement = React.createRef();
        this.cadastreService = new CadastreService();
        this.placeService = new PlaceService();
        this.estimateService = new EstimateService();
    }

    public componentDidMount(): void {
        if (!this.mapElement.current) {
            return;
        }

        const { regionsBounds } = this.state;

        this.map = new mapbox.Map({
            container: this.mapElement.current,
            style: 'mapbox://styles/jamiemh/ckzd02xxi005414p975hwdbkh',
            bounds: regionsBounds,
            fitBoundsOptions: this.getFitBoundsOptions(),
        });

        this.map.on('load', (event: mapbox.MapboxEvent) => {
            this.registerLayerEvents();

            this.map?.loadImage(
                'img/marker-house.png',
                (error, image) => {
                    if (!image) {
                        return;
                    }

                    this.map?.addImage('marker-house', image);
                },
            );

            this.map?.loadImage(
                'img/marker-apartment.png',
                (error, image) => {
                    if (!image) {
                        return;
                    }

                    this.map?.addImage('marker-apartment', image);
                },
            );

            event.target.resize();

            this.loadingScopeBackground(async (): Promise<void> => {
                await this.loadRegions();
            });
        });
    }

    private reset(): void {
        if (!this.map) {
            return;
        }

        const { regionsBounds } = this.state;
        this.fitToLatLngBounds(regionsBounds);

        this.clearLayerAndSource('property-sales');
        this.clearLayerAndSource('sections');
        this.clearLayerAndSource('towns');
        this.clearLayerAndSource('departments');
        this.clearLayerAndSource('regions');

        this.setState({
            region: null,
            department: null,
            town: null,
            section: null,
        });

        this.clearLocationMarker();
    }

    private clearLocationMarker(): void {
        const { locationMarker } = this.state;

        if (locationMarker) {
            locationMarker.remove();
        }
    }

    private showLocationMarker(coordinates: LngLatLike, popup?: Popup): void {
        if (!this.map) {
            return;
        }

        this.clearLocationMarker();

        const element = document.createElement('div');
        element.className = 'location-marker';

        const marker = new mapbox.Marker(element)
            .setLngLat(coordinates)
            .setPopup(popup)
            .addTo(this.map);

        if (popup) {
            marker.togglePopup();
        }

        this.setState({ locationMarker: marker });
    }

    private handleGenericError(): void {
        const { toast } = this.props;
        toast('Une erreur est survenue', 'error', {});
    }

    private showNoDataMessage(): void {
        const { toast } = this.props;
        toast('Aucune donnée foncière n\'est disponible ici', 'info', {});
    }

    public componentDidUpdate(prevProps: MapProps): void {
        const { filter } = this.props;

        if (filter === prevProps.filter) {
            return;
        }

        this.reset();

        this.loadingScopeBackground(async (): Promise<void> => {
            await this.loadRegions();
        });
    }

    private async loadingScope(action: () => Promise<void>): Promise<void> {
        const { setLoading } = this.props;
        setLoading(true);

        try {
            await action();
        } finally {
            setLoading(false);
        }
    }

    private loadingScopeBackground(action: () => Promise<void>): void {
        this.loadingScope(action).catch(this.handleGenericError.bind(this));
    }

    private registerLayerEvents(): void {
        if (!this.map) {
            return;
        }

        this.map.on('click', 'regions-clickable', this.onRegionClick.bind(this));
        this.map.on('click', 'departments-clickable', this.onDepartmentClick.bind(this));
        this.map.on('click', 'towns-clickable', this.onTownClick.bind(this));
        this.map.on('click', 'sections-clickable', this.onSectionClick.bind(this));

        this.map.on('mousemove', 'regions-clickable', (e) => { this.onClickableLayerHover(e, 'regions'); });
        this.map.on('mouseleave', 'regions-clickable', (e) => { this.onClickableLayerHoverOff(e, 'regions'); });

        this.map.on('mousemove', 'departments-clickable', (e) => { this.onClickableLayerHover(e, 'departments'); });
        this.map.on('mouseleave', 'departments-clickable', (e) => { this.onClickableLayerHoverOff(e, 'departments'); });

        this.map.on('mousemove', 'towns-clickable', (e) => { this.onClickableLayerHover(e, 'towns'); });
        this.map.on('mouseleave', 'towns-clickable', (e) => { this.onClickableLayerHoverOff(e, 'towns'); });

        this.map.on('mousemove', 'sections-clickable', (e) => { this.onClickableLayerHover(e, 'sections'); });
        this.map.on('mouseleave', 'sections-clickable', (e) => { this.onClickableLayerHoverOff(e, 'sections'); });

        this.map.on('click', 'property-sales', (e) => {
            this.displaySalePopup(this.map?.queryRenderedFeatures(e.point) || []);
        });

        this.map.on('mouseenter', 'property-sales', this.mouseToPointer);
        this.map.on('mouseleave', 'property-sales', this.mouseToGrab);

        this.map.on('mouseenter', 'property-sales-clusters', this.mouseToPointer);
        this.map.on('mouseleave', 'property-sales-clusters', this.mouseToGrab);

        this.map.on('click', 'property-sales-clusters', (e) => {
            this.handleSalesClusterClick(e);
        });
    }

    private mouseToPointer = (): void => {
        if (!this.map) {
            return;
        }
        this.map.getCanvas().style.cursor = 'pointer';
    };

    private mouseToGrab = (): void => {
        if (!this.map) {
            return;
        }
        this.map.getCanvas().style.cursor = 'grab';
    };

    public render(): React.ReactElement {
        return (
            <div ref={this.mapElement} id="map" />
        );
    }

    private addOutlineLayer(id: string): void {
        if (!this.map) {
            return;
        }

        this.map.addLayer({
            id: `${id}-outline`,
            type: 'line',
            source: id,
            layout: {},
            paint: {
                'line-color': '#171717',
                'line-opacity': 0.6,
                'line-width': 2,
            },
        });
    }

    private addClickableLayer(id: string): void {
        if (!this.map) {
            return;
        }

        const clickableId = `${id}-clickable`;

        this.map.addLayer({
            id: clickableId,
            type: 'fill',
            source: id,
            layout: {},
            paint: {
                'fill-color': '#171717',
                'fill-opacity': [
                    'case',
                    ['boolean', ['feature-state', 'hover'], false],
                    0.2,
                    0.0,
                ],
            },
        });
    }

    private onClickableLayerHover(event: MapMouseEvent, sourceId: string): void {
        const canvas = event.target.getCanvas();
        canvas.style.cursor = 'pointer';

        const hoveredFeatureId = event.features?.[0].id;

        if (!hoveredFeatureId) {
            return;
        }

        const { hoveredFeatureIds } = this.state;
        const currentHoveredFeatureId = hoveredFeatureIds[sourceId];

        if (currentHoveredFeatureId) {
            event.target.setFeatureState({
                source: sourceId,
                id: currentHoveredFeatureId,
            }, {
                hover: false,
            });
        }

        event.target.setFeatureState({
            source: sourceId,
            id: hoveredFeatureId,
        }, {
            hover: true,
        });

        hoveredFeatureIds[sourceId] = hoveredFeatureId as number | null;
        this.setState({ hoveredFeatureIds });
    }

    private onClickableLayerHoverOff(event: MapMouseEvent, sourceId: string): void {
        const canvas = event.target.getCanvas();
        canvas.style.cursor = '';

        const { hoveredFeatureIds } = this.state;
        const currentHoveredFeatureId = hoveredFeatureIds[sourceId];

        if (!currentHoveredFeatureId) {
            return;
        }

        event.target.setFeatureState({
            source: sourceId,
            id: currentHoveredFeatureId,
        }, {
            hover: false,
        });

        hoveredFeatureIds[sourceId] = null;
        this.setState({ hoveredFeatureIds });
    }

    private addPropertyValuesLayer(id: string, valueStats: [number, number, number]): void {
        if (!this.map) {
            return;
        }

        const [min, max, median] = valueStats;
        const { filter } = this.props;

        const minExpr = min > 0 ? [
            min,
            'rgba(0, 255, 0, .4)',
        ] : [];

        const medianExpr = median > 0 && median > min && median < max ? [
            median,
            'rgba(255, 127, 0, .4)',
        ] : [];

        const maxExpr = max > 0 ? [
            max,
            'rgba(255, 0, 0, .4)',
        ] : [];

        this.map.addLayer({
            id,
            type: 'fill',
            source: id,
            layout: {},
            paint: {
                'fill-color': [
                    'interpolate',
                    ['linear'],
                    [
                        'coalesce',
                        ['get', filter.type.toString(), ['get', 'medianValues']],
                        -1,
                    ],
                    -1,
                    'rgba(0, 0, 0, .1)',
                    ...minExpr,
                    ...medianExpr,
                    ...maxExpr,
                ],
            },
        });
    }

    private getMinMaxMedianPropertyValues(
        collection: FeatureCollection<Geometry, MedianValueProperties>,
        type: PropertyType,
    ): [number, number, number] {
        let min = 0;
        let max = 0;
        const values = [];

        for (const feature of collection.features) {
            const value = feature.properties.medianValues?.[type];

            if (!value) {
                continue;
            }

            if (min === 0 || value < min) {
                min = value;
            }

            if (value > max) {
                max = value;
            }

            values.push(value);
        }

        let median = 0;

        if (values.length > 0) {
            median = values.sort()[Math.floor(values.length / 2)];
        }

        return [min, max, median];
    }

    private clearLayerAndSource(id: string): void {
        if (!this.map) {
            return;
        }

        const clickableId = `${id}-clickable`;
        const outlineId = `${id}-outline`;

        if (this.map.getLayer(clickableId) as mapbox.AnyLayer | undefined) {
            this.map.removeLayer(clickableId);
        }

        if (this.map.getLayer(outlineId) as mapbox.AnyLayer | undefined) {
            this.map.removeLayer(outlineId);
        }

        if (this.map.getLayer(id) as mapbox.AnyLayer | undefined) {
            this.map.removeLayer(id);
        }

        const source = this.map.getSource(id) as AnySourceImpl | undefined;

        if (source) {
            this.map.removeSource(id);
        }
    }

    private boundingBoxToLatLngBounds(boundingBox: BBox): mapbox.LngLatBoundsLike {
        const [lon1, lat1, lon2, lat2] = boundingBox;

        return [
            [lon1, lat1],
            [lon2, lat2],
        ];
    }

    private getFitBoundsOptions(): mapbox.FitBoundsOptions {
        const windowWidth = window.innerWidth;

        return {
            padding: {
                top: 80,
                right: (windowWidth >= 1000 ? 400 : 20),
                bottom: (windowWidth < 1000 ? 250 : 20),
                left: 20,
            },
            easing: (t): number => 1 - (1 - t) ** 3,
        };
    }

    private fitToLatLngBounds(latLngBounds: LngLatBoundsLike): void {
        if (!this.map) {
            return;
        }

        this.map.fitBounds(latLngBounds, this.getFitBoundsOptions());
    }

    private fitToBoundingBox(boundingBox: BBox): void {
        const latLngBounds = this.boundingBoxToLatLngBounds(boundingBox);
        this.fitToLatLngBounds(latLngBounds);
    }

    private async loadRegions(): Promise<void> {
        if (!this.map) {
            return;
        }

        const { filter } = this.props;
        const regionsData = await this.cadastreService.getRegions(filter.year);

        this.map.addSource('regions', {
            type: 'geojson',
            data: regionsData.geojson,
        });

        if (!regionsData.medianValues[filter.type]) {
            this.showNoDataMessage();
        }

        const valueStats = this.getMinMaxMedianPropertyValues(regionsData.geojson, filter.type);
        const { country } = this.state;

        this.setState({
            country: {
                ...country,
                medianValues: regionsData.medianValues,
                medianValuesByYear: regionsData.medianValuesByYear,
                valueStats,
                name: 'France',
            },
        });

        this.addOutlineLayer('regions');
        this.addClickableLayer('regions');
        this.addPropertyValuesLayer('regions', valueStats);

        const { handleSelectedFeatureChange } = this.props;
        handleSelectedFeatureChange(this.state);
    }

    private onRegionClick(event: MapMouseEvent): void {
        if (!this.map) {
            return;
        }

        const feature = event.features?.[0];

        if (!feature) {
            return;
        }

        this.clearLocationMarker();

        this.loadingScopeBackground(async (): Promise<void> => {
            const properties = feature.properties as MapBoxProperties;
            const { region } = this.state;

            this.setState({
                region: {
                    ...region,
                    ...properties,
                    medianValues: JSON.parse(properties.medianValues) as MedianValueProperties['medianValues'],
                    medianValuesByYear: null,
                    valueStats: region?.valueStats || null,
                },
                department: null,
                town: null,
                section: null,
            });

            this.map?.setFilter('regions-clickable', ['!=', 'id', properties.id]);
            this.map?.setFilter('regions-outline', ['!=', 'id', properties.id]);

            await this.loadDepartments(properties.id);

            this.setLayerVisibility('regions', false);
            this.clearSalesLayerAndSource();
            this.clearLayerAndSource('sections');
            this.clearLayerAndSource('towns');

            const { handleSelectedFeatureChange } = this.props;
            handleSelectedFeatureChange(this.state);
        });
    }

    private async loadDepartments(regionId: string, departments: FeaturesData<DepartmentProperties> | null = null): Promise<void> {
        if (!this.map) {
            return;
        }

        const { filter } = this.props;
        let loadedDepartments;

        if (!departments) {
            loadedDepartments = await this.cadastreService.getDepartments(regionId, filter.year);
        } else {
            loadedDepartments = departments;
        }

        const existingSource = this.map.getSource('departments') as GeoJSONSource | undefined;

        if (existingSource) {
            this.clearLayerAndSource('departments');
        }

        this.map.addSource('departments', {
            type: 'geojson',
            data: loadedDepartments.geojson,
        });

        if (!loadedDepartments.medianValues[filter.type]) {
            this.showNoDataMessage();
        }

        const valueStats = this.getMinMaxMedianPropertyValues(loadedDepartments.geojson, filter.type);

        const { region } = this.state;

        if (region?.id) {
            this.setState({
                region: {
                    ...region,
                    medianValues: loadedDepartments.medianValues,
                    medianValuesByYear: loadedDepartments.medianValuesByYear,
                    valueStats,
                },
                departmentsBounds: loadedDepartments.geojson.bbox ? this.boundingBoxToLatLngBounds(loadedDepartments.geojson.bbox) : null,
            });
        }

        this.addOutlineLayer('departments');
        this.addClickableLayer('departments');
        this.addPropertyValuesLayer('departments', valueStats);

        const { bbox } = loadedDepartments.geojson;
        if (bbox) {
            this.fitToBoundingBox(bbox);
        }
    }

    private onDepartmentClick(event: MapMouseEvent): void {
        if (!this.map) {
            return;
        }

        const feature = event.features?.[0];

        if (!feature) {
            return;
        }

        const { region, department } = this.state;
        const properties = feature.properties as MapBoxProperties;

        if (department?.id === properties.id) {
            return;
        }

        this.clearLocationMarker();

        this.loadingScopeBackground(async (): Promise<void> => {
            if (!this.map) {
                return;
            }

            this.setState({
                department: {
                    ...department,
                    ...properties,
                    medianValues: JSON.parse(properties.medianValues) as MedianValueProperties['medianValues'],
                    medianValuesByYear: null,
                    valueStats: region?.valueStats || null,
                },
                town: null,
                section: null,
            });

            this.map.setFilter('departments-clickable', ['!=', 'id', properties.id]);
            this.map.setFilter('departments-outline', ['!=', 'id', properties.id]);

            await this.loadTowns(properties.id);

            this.setLayerVisibility('departments', false);
            this.clearSalesLayerAndSource();
            this.clearLayerAndSource('sections');

            const { handleSelectedFeatureChange } = this.props;
            handleSelectedFeatureChange(this.state);
        });
    }

    private async loadTowns(departmentId: string, towns: FeaturesData<TownProperties> | null = null): Promise<void> {
        if (!this.map) {
            return;
        }

        const { filter } = this.props;
        let loadedTowns;

        if (!towns) {
            loadedTowns = await this.cadastreService.getTowns(departmentId, filter.year);
        } else {
            loadedTowns = towns;
        }

        const existingSource = this.map.getSource('towns') as GeoJSONSource | undefined;

        if (existingSource) {
            this.clearLayerAndSource('towns');
        }

        this.map.addSource('towns', {
            type: 'geojson',
            data: loadedTowns.geojson,
        });

        if (!loadedTowns.medianValues[filter.type]) {
            this.showNoDataMessage();
        }

        const valueStats = this.getMinMaxMedianPropertyValues(loadedTowns.geojson, filter.type);

        const { department } = this.state;

        if (department?.id) {
            this.setState({
                department: {
                    ...department,
                    medianValues: loadedTowns.medianValues,
                    medianValuesByYear: loadedTowns.medianValuesByYear,
                    valueStats,
                },
                townsBounds: loadedTowns.geojson.bbox ? this.boundingBoxToLatLngBounds(loadedTowns.geojson.bbox) : null,
            });
        }

        this.addOutlineLayer('towns');
        this.addClickableLayer('towns');
        this.addPropertyValuesLayer('towns', valueStats);

        const { bbox } = loadedTowns.geojson;
        if (bbox) {
            this.fitToBoundingBox(bbox);
        }
    }

    private onTownClick(event: MapMouseEvent): void {
        if (!this.map) {
            return;
        }

        const feature = event.features?.[0];

        if (!feature) {
            return;
        }

        this.clearLocationMarker();

        this.loadingScopeBackground(async (): Promise<void> => {
            if (!this.map) {
                return;
            }

            const { town } = this.state;
            const properties = feature.properties as MapBoxProperties;

            this.setState({
                town: {
                    ...town,
                    ...properties,
                    medianValues: JSON.parse(properties.medianValues) as MedianValueProperties['medianValues'],
                    medianValuesByYear: null,
                    valueStats: town?.valueStats || null,
                    postCode: properties.postCode || '',
                },
                section: null,
            });

            this.map.setFilter('towns-clickable', ['!=', 'id', properties.id]);
            this.map.setFilter('towns-outline', ['!=', 'id', properties.id]);

            await this.loadSections(properties.id);

            this.setLayerVisibility('towns', false);
            this.clearSalesLayerAndSource();

            const { handleSelectedFeatureChange } = this.props;
            handleSelectedFeatureChange(this.state);
        });
    }

    private async loadSections(townId: string, sections: FeaturesData<SectionProperties> | null = null): Promise<void> {
        if (!this.map) {
            return;
        }

        const { filter } = this.props;
        let loadedSections;

        if (!sections) {
            loadedSections = await this.cadastreService.getSections(townId, filter.year);
        } else {
            loadedSections = sections;
        }

        const existingSource = this.map.getSource('sections') as GeoJSONSource | undefined;

        if (existingSource) {
            this.clearLayerAndSource('sections');
        }

        this.clearSalesLayerAndSource();

        this.map.addSource('sections', {
            type: 'geojson',
            data: loadedSections.geojson,
        });

        if (!loadedSections.medianValues[filter.type]) {
            this.showNoDataMessage();
        }

        const valueStats = this.getMinMaxMedianPropertyValues(loadedSections.geojson, filter.type);
        const { town } = this.state;

        if (town?.id) {
            this.setState({
                town: {
                    ...town,
                    medianValues: loadedSections.medianValues,
                    medianValuesByYear: loadedSections.medianValuesByYear,
                    valueStats,
                },
            });
        }

        this.addOutlineLayer('sections');
        this.addClickableLayer('sections');
        this.addPropertyValuesLayer('sections', valueStats);

        const { bbox } = loadedSections.geojson;
        if (bbox) {
            this.fitToBoundingBox(bbox);
        }
    }

    private onSectionClick(event: MapMouseEvent): void {
        if (!this.map) {
            return;
        }

        const feature = event.features?.[0];

        if (!feature) {
            return;
        }

        const properties = feature.properties as MapBoxProperties;

        const filter = ['!=', 'id', properties.id];
        this.map.setFilter('sections', filter);
        this.map.setFilter('sections-clickable', filter);

        this.clearLocationMarker();
        this.clearSalesLayerAndSource();

        this.loadingScopeBackground(async (): Promise<void> => {
            await this.loadSales(properties.id);
            const { handleSelectedFeatureChange } = this.props;
            handleSelectedFeatureChange(this.state);
        });
    }

    private displaySalePopup(features: MapboxGeoJSONFeature[], featureIndex: number = 0): void {
        if (!this.map || !features.length || features[featureIndex].source !== 'property-sales') {
            return;
        }

        let propertySales = features;

        if (featureIndex === 0) {
            propertySales = features
                .filter((feature) => feature.source === 'property-sales')
                .sort((a, b) => (new Date(a.properties?.date) > new Date(b.properties?.date) ? -1 : 1));
        }

        if (!propertySales.length) {
            return;
        }

        const { coordinates } = propertySales[featureIndex].geometry as Point;
        const { properties } = propertySales[featureIndex];

        this.map.flyTo({
            center: [coordinates[0], coordinates[1]],
            duration: 500,
        });

        if (!properties) {
            return;
        }

        this.map.getCanvas().style.cursor = 'pointer';

        const popupElement = document.createElement('div');
        popupElement.className = 'sale-popup';

        popupElement.innerHTML = `
            <div class="heading">
                <span>${`${properties.streetNumber} ` || ''}${properties.streetName}</span>
                <small>${properties.postCode}</small>
            </div>

            <div class="content">
                <div class="property-values">
                    <div class="item">
                        <span class="material-icons">chair</span>
                        ${properties.roomCount} pièce(s)
                    </div>

                    <div class="item">
                        <span class="material-icons">square_foot</span>
                        <div>${properties.buildingSurfaceArea} m<sup>2</sup></div>
                    </div>

                    <div class="item">
                        <span class="material-icons">park</span>
                        <div>${properties.landSurfaceArea} m<sup>2</sup></div>
                    </div>
                </div>

                <div class="sale-values">
                    ${this.numberFormat.format(properties.value)} €
                    <small>${formatDate(new Date(properties.date))}</small>
                </div>
            </div>
        `;

        if (propertySales.length > 1) {
            const controls = document.createElement('div');
            controls.className = 'controls';

            const previousButton = document.createElement('button');
            previousButton.className = 'link';
            previousButton.innerHTML = 'Précédent';

            if (featureIndex === 0) {
                previousButton.disabled = true;
            }

            controls.appendChild(previousButton);

            previousButton.addEventListener('click', () => {
                this.displaySalePopup(propertySales, featureIndex - 1);
            });

            const paginator = document.createElement('span');
            paginator.innerHTML = `${featureIndex + 1} / ${propertySales.length}`;
            controls.appendChild(paginator);

            const nextButton = document.createElement('button');
            nextButton.className = 'link';
            nextButton.innerHTML = 'Suivant';

            if (featureIndex === propertySales.length - 1) {
                nextButton.disabled = true;
            }

            controls.appendChild(nextButton);

            nextButton.addEventListener('click', () => {
                this.displaySalePopup(propertySales, featureIndex + 1);
            });

            popupElement.appendChild(controls);
        }

        const { propertySalePopup } = this.state;
        propertySalePopup?.remove();

        const popup = new Popup({
            closeButton: true,
            closeOnClick: false,
            maxWidth: 'none',
            offset: 30,
        }).setLngLat(coordinates as LngLatLike)
            .setDOMContent(popupElement)
            .addTo(this.map);

        this.setState({ propertySalePopup: popup });
    }

    private handleSalesClusterClick(e: MapMouseEvent): void {
        if (!this.map) {
            return;
        }

        const features = this.map.queryRenderedFeatures(e.point, {
            layers: ['property-sales-clusters'],
        });

        if (!features.length) {
            return;
        }

        if (!features[0].properties) {
            return;
        }

        const clusterId = features[0].properties.cluster_id as number;

        const source: GeoJSONSource = this.map.getSource('property-sales') as GeoJSONSource;

        source.getClusterExpansionZoom(
            clusterId,
            (err, zoom) => {
                if (err) {
                    return;
                }

                const { geometry } = features[0];

                if (geometry.type === 'Point') {
                    this.map?.easeTo({
                        center: [geometry.coordinates[0], geometry.coordinates[1]],
                        zoom,
                    });
                }
            },
        );
    }

    private clearSalesLayerAndSource(): void {
        const { propertySalePopup } = this.state;
        propertySalePopup?.remove();

        this.clearLayerAndSource('property-sales-clusters');
        this.clearLayerAndSource('property-sales-cluster-count');
        this.clearLayerAndSource('property-sales');
    }

    private async loadSales(sectionId: string): Promise<void> {
        if (!this.map) {
            return;
        }

        const { filter } = this.props;
        const sales = await this.cadastreService.getSales(sectionId, filter.type, filter.year);

        if (sales.geojson.features.length === 0) {
            this.showNoDataMessage();
        }

        this.map.addSource('property-sales', {
            type: 'geojson',
            data: sales.geojson,
            cluster: true,
            clusterMinPoints: 4,
        });

        this.map.addLayer({
            id: 'property-sales-clusters',
            type: 'circle',
            source: 'property-sales',
            filter: ['has', 'point_count'],
            paint: {
                'circle-color': '#fff',
                'circle-radius': 20,
                'circle-stroke-color': '#171717',
                'circle-stroke-width': 1,
            },
        });

        this.map.addLayer({
            id: 'property-sales-cluster-count',
            type: 'symbol',
            source: 'property-sales',
            filter: ['has', 'point_count'],
            layout: {
                'text-field': '{point_count_abbreviated}',
                'text-size': 14,
            },
            paint: {
                'text-color': '#000',
            },
        });

        this.map.addLayer({
            id: 'property-sales',
            type: 'symbol',
            source: 'property-sales',
            filter: ['!', ['has', 'point_count']],
            layout: {
                'icon-image': [
                    'match',
                    ['get', 'type'],
                    1,
                    'marker-house',
                    'marker-apartment',
                ],
                'icon-allow-overlap': true,
            },
        });

        this.setState({
            section: {
                id: sectionId,
                medianValues: sales.medianValues,
                medianValuesByYear: sales.medianValuesByYear,
                valueStats: null,
            },
        });

        const { bbox } = sales.geojson;

        if (bbox) {
            this.fitToBoundingBox(bbox);
        }
    }

    private setLayerVisibility(id: string, isVisible: boolean): boolean {
        if (!this.map) {
            return false;
        }

        if (this.map.getLayer(id) as mapbox.AnyLayer | undefined) {
            this.map.setLayoutProperty(id, 'visibility', isVisible ? 'visible' : 'none');
            return true;
        }

        return false;
    }

    public async zoomToParentCollection(level: number): Promise<void> {
        if (!this.map) {
            return;
        }

        this.clearLocationMarker();

        const {
            region, department, regionsBounds, departmentsBounds, townsBounds,
        } = this.state;
        const { handleSelectedFeatureChange } = this.props;

        if (level <= 2) {
            this.clearLayerAndSource('sections');
            this.clearSalesLayerAndSource();
        }

        if (level <= 1) {
            this.clearLayerAndSource('towns');
        }

        if (level === 0) {
            this.setState({
                region: null,
                department: null,
                town: null,
                section: null,
            });

            this.clearLayerAndSource('departments');

            if (this.setLayerVisibility('regions', true)) {
                this.map.setFilter('regions-clickable', null);
                this.map.setFilter('regions-outline', null);
            } else {
                await this.loadRegions();
            }

            this.fitToLatLngBounds(regionsBounds);
        } else if (level === 1 && region?.id) {
            this.setState({
                department: null,
                town: null,
                section: null,
            });

            if (this.setLayerVisibility('departments', true)) {
                this.map.setFilter('departments-clickable', null);
                this.map.setFilter('departments-outline', null);
            } else {
                await this.loadDepartments(region.id);
            }

            if (departmentsBounds) {
                this.fitToLatLngBounds(departmentsBounds);
            }
        } else if (level === 2 && department?.id) {
            this.setState({
                town: null,
                section: null,
            });

            if (this.setLayerVisibility('towns', true)) {
                this.map.setFilter('towns-clickable', null);
                this.map.setFilter('towns-outline', null);
            } else {
                await this.loadTowns(department.id);
            }

            if (townsBounds) {
                this.fitToLatLngBounds(townsBounds);
            }
        }

        setTimeout(() => {
            handleSelectedFeatureChange(this.state);
        });
    }

    private async getPlaceFromCoordinates(coordinates: Coordinates): Promise<Place<Geometry> | null> {
        const places = await this.placeService.getPlacesAtCoordinates(coordinates);

        if (places.length === 0) {
            return null;
        }

        places.sort((a, b) => ((a.type < b.type) ? 1 : -1));

        return places[0];
    }

    private async loadRegionPlace(place: Place<Geometry>): Promise<void> {
        if (!this.map || place.type !== PlaceType.REGION) {
            return;
        }

        this.setLayerVisibility('regions', false);
        this.clearLayerAndSource('departments');
        this.clearLayerAndSource('towns');
        this.clearLayerAndSource('sections');

        const { filter } = this.props;

        const departments = await this.cadastreService.getDepartments(place.id, filter.year);
        const boundingBox = departments.geojson.bbox;
        await this.loadDepartments(place.id, departments);

        this.map.setFilter('regions-clickable', ['!=', 'id', place.id]);
        this.map.setFilter('regions-outline', ['!=', 'id', place.id]);

        const valueStats = this.getMinMaxMedianPropertyValues(departments.geojson, filter.type);

        this.setState({
            region: {
                id: place.id,
                name: place.name,
                medianValues: departments.medianValues,
                medianValuesByYear: departments.medianValuesByYear,
                valueStats,
            },
            department: null,
            town: null,
            section: null,
            departmentsBounds: boundingBox ? this.boundingBoxToLatLngBounds(boundingBox) : null,
        });
    }

    private async loadDepartmentPlace(place: Place<Geometry>): Promise<void> {
        if (!this.map || place.type !== PlaceType.DEPARTMENT) {
            return;
        }

        this.setLayerVisibility('departments', false);
        this.clearLayerAndSource('sections');
        this.clearLayerAndSource('towns');

        const { filter } = this.props;

        const towns = await this.cadastreService.getTowns(place.id, filter.year);
        const boundingBox = towns.geojson.bbox;
        await this.loadTowns(place.id, towns);

        this.map.setFilter('departments-clickable', ['!=', 'id', place.id]);
        this.map.setFilter('departments-outline', ['!=', 'id', place.id]);

        const valueStats = this.getMinMaxMedianPropertyValues(towns.geojson, filter.type);

        this.setState({
            department: {
                id: place.id,
                name: place.name,
                medianValues: towns.medianValues,
                medianValuesByYear: towns.medianValuesByYear,
                valueStats,
            },
            town: null,
            section: null,
            townsBounds: boundingBox ? this.boundingBoxToLatLngBounds(boundingBox) : null,
        });
    }

    private async loadTownPlace(place: Place<Geometry>): Promise<void> {
        if (!this.map || place.type !== PlaceType.TOWN) {
            return;
        }

        this.setLayerVisibility('towns', false);
        this.clearLayerAndSource('sections');

        const { filter } = this.props;

        const sections = await this.cadastreService.getSections(place.id, filter.year);
        await this.loadSections(place.id, sections);

        this.map.setFilter('towns-clickable', ['!=', 'id', place.id]);
        this.map.setFilter('towns-outline', ['!=', 'id', place.id]);

        const valueStats = this.getMinMaxMedianPropertyValues(sections.geojson, filter.type);

        this.setState({
            town: {
                id: place.id,
                name: place.shortName,
                postCode: place.postCode || '',
                medianValues: sections.medianValues,
                medianValuesByYear: sections.medianValuesByYear,
                valueStats,
            },
            section: null,
        });
    }

    private async loadSectionPlace(place: Place<Geometry>): Promise<void> {
        if (!this.map || place.type !== PlaceType.SECTION) {
            return;
        }

        await this.loadSales(place.id);

        const filter = ['!=', 'id', place.id];
        this.map.setFilter('sections', filter);
        this.map.setFilter('sections-clickable', filter);
    }

    private loadPlaceAtPoint(place: Place<Point>): void {
        if (!this.map || !place.geometry) {
            return;
        }

        this.showLocationMarker([place.geometry.coordinates[0], place.geometry.coordinates[1]]);
    }

    public async loadPlaceHierarchy(place: Place<Geometry>): Promise<void> {
        if (place.parent) {
            await this.loadPlaceHierarchy(place.parent);
        }

        switch (place.type) {
        case PlaceType.REGION:
            await this.loadRegionPlace(place);
            break;

        case PlaceType.DEPARTMENT:
            await this.loadDepartmentPlace(place);
            break;

        case PlaceType.TOWN:
            await this.loadTownPlace(place);
            break;

        case PlaceType.SECTION:
            await this.loadSectionPlace(place);
            break;

        case PlaceType.STREET:
        case PlaceType.SAID_PLACE:
        case PlaceType.ADDRESS:
            this.loadPlaceAtPoint(place as Place<Point>);
            break;

        default:
            break;
        }
    }

    public async moveToPlace(place: Place<Geometry>): Promise<void> {
        if (!this.map) {
            return;
        }

        this.clearLocationMarker();
        const hierarchy = await this.placeService.getPlaceHierarchy(place.id, place.type);

        await this.loadingScope(async (): Promise<void> => {
            await this.loadPlaceHierarchy(hierarchy);
            const { handleSelectedFeatureChange } = this.props;
            handleSelectedFeatureChange(this.state);
        });
    }

    public async getEstimate(request: EstimateRequest): Promise<void> {
        if (!this.map) {
            return;
        }

        await this.loadingScope(async (): Promise<void> => {
            if (!this.map) {
                return;
            }

            const place = await this.getPlaceFromCoordinates(request.coordinates);

            if (!place) {
                return;
            }

            await this.moveToPlace(place);

            const result = await this.estimateService.getEstimate(request);

            const popup = new mapbox.Popup({
                offset: 30,
                closeButton: false,
                maxWidth: 'none',
            }).setHTML(`
                <div class="sale-popup">
                    <div class="heading">Votre estimation</div>

                    <div class="content">
                        <div class="property-values">
                            <div class="item">
                                <span class="material-icons">chair</span>
                                ${request.rooms} pièce(s)
                            </div>

                            <div class="item">
                                <span class="material-icons">square_foot</span>
                                <div>${request.buildingSurfaceArea} m<sup>2</sup></div>
                            </div>

                            <div class="item">
                                <span class="material-icons">park</span>
                                <div>${request.landSurfaceArea} m<sup>2</sup></div>
                            </div>
                        </div>

                        <div class="sale-values">
                            ${this.numberFormat.format(result.value)} €
                        </div>
                    </div>
                </div>
            `);

            this.showLocationMarker([request.coordinates.longitude, request.coordinates.latitude], popup);
        });
    }

    public async onGeolocate(coords: Coordinates): Promise<void> {
        if (!this.map) {
            return;
        }

        await this.loadingScope(async (): Promise<void> => {
            if (!this.map) {
                return;
            }

            const place = await this.getPlaceFromCoordinates(coords);

            if (!place) {
                return;
            }

            await this.moveToPlace(place);
            this.showLocationMarker([coords.longitude, coords.latitude]);
        });
    }
}

import React from 'react';

import './Modal.scss';

interface ModalProps {
    title: string;
    onClose: () => void;
    children: React.ReactNode;
}

interface ModalState {

}

export default class Modal extends React.Component<ModalProps, ModalState> {
    public constructor(props: ModalProps) {
        super(props);
    }

    public render(): React.ReactElement {
        const { title, onClose, children } = this.props;

        return (
            <div className="modal-wrap">
                <div className="overlay" onClick={(): void => { onClose(); }} aria-hidden="true" />
                <div className="modal">
                    <div className="header">
                        <span className="title">{title}</span>
                        <button className="close" type="button" onClick={(): void => { onClose(); }}>
                            <span className="material-icons">close</span>
                        </button>
                    </div>
                    <div className="content">
                        { children }
                    </div>
                </div>
            </div>
        );
    }
}

import { Geometry } from 'geojson';

import { ApiService } from './apiService';
import { Coordinates } from '../data/coordinates';
import { Place } from '../data/place';
import { PlaceType } from '../data/placeType';

export class PlaceService extends ApiService {
    public getPlacesByName(name: string, type: PlaceType | undefined = undefined, limit: number = 5): Promise<Place<Geometry>[]> {
        let params;

        if (type) {
            params = { name, type, limit };
        } else {
            params = { name, limit };
        }

        return this.makeGetRequest('/places', params);
    }

    public getPlacesAtCoordinates(coordinates: Coordinates, type: PlaceType | undefined = undefined, limit: number = 5): Promise<Place<Geometry>[]> {
        let params;

        if (type) {
            params = {
                latitude: coordinates.latitude, longitude: coordinates.longitude, type, limit,
            };
        } else {
            params = {
                latitude: coordinates.latitude, longitude: coordinates.longitude, limit,
            };
        }

        return this.makeGetRequest('/places', params);
    }

    public getPlaceHierarchy(id: string | number, type: PlaceType): Promise<Place<Geometry>> {
        return this.makeGetRequest(`/places/${id}`, { type });
    }
}

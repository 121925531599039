import React from 'react';

import Modal from './Modal';

import './HelpModal.scss';

interface HelpModalProps {
    onClose: () => void;
    onPermanentlyDismiss: () => void;
}

interface HelpModalState {

}

export default class HelpModal extends React.Component<HelpModalProps, HelpModalState> {
    public constructor(props: HelpModalProps) {
        super(props);
    }

    public render(): React.ReactElement {
        const { onClose, onPermanentlyDismiss } = this.props;

        return (
            <Modal title="Bienvenue sur Estimmo" onClose={onClose}>
                <h2 className="mb3">Votre référence du prix de l&apos;immobilier</h2>

                <div className="row mb2 help-features">
                    <div className="item sm-col4">
                        <span className="material-icons mb">map</span>
                        Carte de prix
                    </div>

                    <div className="item sm-col4">
                        <span className="material-icons mb">sell</span>
                        Estimation
                    </div>

                    <div className="item sm-col4">
                        <span className="material-icons mb">timeline</span>
                        Évolution du prix
                    </div>
                </div>

                <p>
                    Estimmo vous offre une base de données détaillée de transactions foncières sur toute la France.
                    Utilisez la carte de prix pour connaître le prix de l&apos;immobilier autour de vous ainsi que l&apos;évolution par an.
                    Il est possible de rechercher par adresse, commune, département ou région ou d&apos;utiliser la fonction de géolocalisation.
                </p>

                <p>
                    Vous pouvez également estimer la valeur de votre bien en complétant les informations dans le formulaire
                    accessible depuis le bouton &quot;Estimer mon bien&quot;.
                </p>

                <p>
                    De plus, pour tout renseignement ou question, n&apos;hésitez pas nous contacter via l&apos;onglet
                    &quot;Nous Contacter&quot; présent en haut à droite. L&apos;application Estimmo est également disponible sur téléphones Android.
                </p>

                <button type="button" className="button primary right ml" onClick={(): void => { onClose(); }}>
                    <span className="material-icons">done</span>
                    Fermer
                </button>

                <button type="button" className="button right" onClick={(): void => { onPermanentlyDismiss(); }}>
                    <span className="material-icons">close</span>
                    Ne plus afficher
                </button>
            </Modal>
        );
    }
}

import axios from 'axios';

import { constants } from '../constants';
import { RequestParams } from '../data/requestParams';

export abstract class ApiService {
    protected async makeGetRequest<T>(path: string, params: RequestParams = {}): Promise<T> {
        const response = await axios.get<T>(constants.ApiUrl + path, { params });
        return response.data;
    }

    protected async makePostRequest<T, U>(path: string, data: U): Promise<T> {
        const response = await axios.post(constants.ApiUrl + path, data);
        return response.data as T;
    }
}

import React from 'react';

import { MapFilter } from '../data/mapFilter';
import { PropertyType } from '../data/propertyType';

import './Filter.scss';

interface FilterProps {
    filter: MapFilter;
    onChange: (filter: MapFilter) => void;
}

interface FilterState {
    isOpen: boolean;
}

export default class Filter extends React.Component<FilterProps, FilterState> {
    public constructor(props: FilterProps) {
        super(props);

        this.state = {
            isOpen: false,
        };
    }

    private toggle(): void {
        const { isOpen } = this.state;
        this.setState({ isOpen: !isOpen });
    }

    public render(): React.ReactElement {
        const { onChange, filter } = this.props;
        const { isOpen } = this.state;

        return (
            <div id="filter">
                <button className="button" type="button" onClick={this.toggle.bind(this)}>
                    <span className="material-icons">tune</span>
                </button>

                { isOpen && (
                    <div className="dropdown">
                        <form className="row gaps">
                            <div className="sm-col6">
                                <label htmlFor="filter-type">
                                    <span className="material-icons">maps_home_work</span>
                                    Type de bien
                                </label>
                                <select
                                    id="filter-type"
                                    value={filter.type}
                                    onChange={(e): void => {
                                        let type;

                                        switch (e.target.value) {
                                        case '1': type = PropertyType.HOUSE; break;
                                        case '2': type = PropertyType.APARTMENT; break;
                                        default: type = PropertyType.ALL; break;
                                        }

                                        onChange({
                                            type,
                                            year: filter.year,
                                        });
                                    }}
                                >
                                    <option value="0">Tous les types</option>
                                    <option value="1">Maisons</option>
                                    <option value="2">Appartements</option>
                                </select>
                            </div>
                            <div className="sm-col6">
                                <label htmlFor="filter-year">
                                    <span className="material-icons">calendar_today</span>
                                    Année de vente
                                </label>
                                <select
                                    id="filter-year"
                                    value={filter.year ?? ''}
                                    onChange={(e): void => {
                                        let year;

                                        if (e.target.value === '') {
                                            year = null;
                                        } else {
                                            year = parseInt(e.target.value, 10);
                                        }

                                        onChange({
                                            type: filter.type,
                                            year,
                                        });
                                    }}
                                >
                                    <option value="">Toutes les années</option>
                                    <option value="2014">2014</option>
                                    <option value="2015">2015</option>
                                    <option value="2016">2016</option>
                                    <option value="2017">2017</option>
                                    <option value="2018">2018</option>
                                    <option value="2019">2019</option>
                                    <option value="2020">2020</option>
                                    <option value="2021">2021</option>
                                    <option value="2022">2022</option>
                                    <option value="2023">2023</option>
                                </select>
                            </div>
                        </form>
                    </div>
                )}
            </div>
        );
    }
}
